import { post, deletes, get, downFile } from 'common/request'

export default {
  save: params => post('/dept/add', params),
  delete: params => deletes('/dept/del', params),
  edit: params => post('/dept/update', params),
  list: params => get('/login-log/queryList', params),
  deleteBatch: params => deletes('/login-log/deleteBatch', params),
  exportXls: params => downFile('/login-log/exportXls', params),
  // 获取组织机构
  deptList: args => get('/dept/list', args),
  // 获取组织机构 树结构
  deptTreeList: args => get('/dept/queryTree', args)
}
