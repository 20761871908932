<template>
  <div >
    <ax-table
      ref="logTable"
      :columns="columns"
      :searchActions='searchActions'
      :show-search="true"
      :searchForm="searchForm"
      :scroll="{ y: '55vh', x: '80vw' }"
      @delect="del"
      @export="expor"
      :sqlParams="sqlParams"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :dataSourceApi="api.list"
    >
    </ax-table>
  </div>
</template>

<script>
import api from './api'
const searchForm = [
  { label: '用户名', type: 'input', model: 'userName', options: {}, formItem: {}, col: { span: 6 } },
  { label: '所在单位', type: 'treeSelect', model: 'placeTheNuitId', options: {}, formItem: {}, col: { span: 6 } },
  { label: '主机地址', type: 'input', model: 'ipaddr', options: {}, formItem: {}, col: { span: 6 } }
]
export default {
  name: 'loginLog',
  data () {
    return {
      api,
      sqlParams: { like: ['userName', 'ipaddr'] },
      columns: this.$ax.tools.initColumn([
        {
          title: '时间',
          dataIndex: 'accessTime',
          ellipsis: true,
          key: 2,
          width: 80
        },
        {
          title: '用户名',
          dataIndex: 'userName',
          ellipsis: true,
          key: 3,
          width: 80
        },
        {
          title: '所在单位',
          dataIndex: 'placeTheNuit',
          ellipsis: true,
          key: 4
        },
        {
          title: '业务类别',
          dataIndex: 'businessTypeContext',
          ellipsis: true,
          key: 5
        },
        {
          title: '访问主机地址',
          dataIndex: 'ipaddr',
          ellipsis: true,
          key: 6
        }
      ]),
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      searchForm: this.$common.initGridFormData(searchForm, { layout: 'horizontal', labelWidth: 70 }),
      searchActions: [
        { name: 'search', text: '查询' },
        { name: 'reset', text: '重置' },
        { name: 'export', text: '导出Excel' },
        { name: 'delect', text: '删除' }
      ]
    }
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  async mounted () {
    await this.deptList()
  },
  methods: {
    async deptList () {
      await api.deptTreeList().then(res => {
        const options = res.data
        this.$refs.logTable.$children[0].$refs.searchForm.setFormItemProp('placeTheNuitId', { options: { treeData: options, allowClear: true } })
      })
    },
    onSelectChange (selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys
    },
    expor () {
      api.exportXls({
        fileName: '登录日志.xls',
        params: this.selectedRowKeys.join(',')
      })
    },
    del () {
      var that = this
      if (that.selectedRowKeys.length === 0) {
        this.$message.warning('请勾选要删除的数据！')
      } else {
        this.$confirm({
          title: '是否删除',
          content: '确认是否删除当前选中日志',
          cancelText: '取消',
          okText: '确定',
          onOk: function () {
            api.deleteBatch({ ids: that.selectedRowKeys.join(',') }).then(res => {
              that.$message.success(res.message)
              that.$refs.logTable.getDataSource()
            })
          }
        })
      }
    },
    reset () {
      const obj = {}
      Object.keys(this.$refs.formBox.getFieldsValue()).forEach(res => {
        obj[res] = ''
      })
      this.$refs.formBox.setFieldsValue(obj)
    }
  }
}
</script>

<style scoped>
</style>
